import clientApi from "@/shared/api/base-api.ts";
import {apiKeys} from "@/shared/constants/api-keys.ts";

export const getCoreActivitiesApi = async (lang: string | null, type: number) => {
    const response = await clientApi.get(apiKeys.coreActivities, {
        params: {
            per_page:'99',
            type,
            lang
        }
    })
    return response?.data?.data
}