import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {INewsModalStore, IInitialState} from './types';

const initialState: IInitialState = {
    newsModal: false,
};

export const useNewsModalStore = create<INewsModalStore>()(
    devtools((set) => ({
        ...initialState,
        setNewsModal: (e) => {
            set({newsModal: e});
        }
    })),
);
