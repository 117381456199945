import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {getNewsApi} from "@/entities/news-api/index.ts";
import {toast} from "react-toastify";
import {FindLang} from "@/shared/libs/functions.ts";

export const useGetNewsQuery = (main: number) => {
    const lang = localStorage.getItem('language') || 'uz'
    return useQuery([apiKeys.news, main,lang], () => getNewsApi(FindLang(lang), main), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
