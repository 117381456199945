import s from "./styles.module.scss"
import {ActionIcon, Drawer, Flex, Text} from "@mantine/core";
import {NavbarData} from "@/widgets/layout/navbar/ui/model/libs.ts";
import {useState} from "react";
import cx from "clsx";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import {ReactComponent as IconBurger} from "@/shared/assets/images/burger.svg";
import {LanguageSelect} from "@/entities/language-select";
import {useTranslation} from "react-i18next";

export const Navbar = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const [opened, setOpened] = useState(false)
    const [active, setActive] = useState(1)
    const [scroll] = useWindowScroll();

    const handleActive = (id: number) => {
        const block = document.querySelector(`#section${id}`)!;
        block.scrollIntoView({behavior: 'smooth', block: 'start'});
        setActive(id)
        setOpened(false)
    }

    return (
        <>
            <div className={cx(s.navbar, {[s.active]: scroll.y > 70})}>
                {
                    !matches ? <>
                            {NavbarData.map((i) => (
                                <Text
                                    key={i?.id}
                                    component={'p'}
                                    className={cx(s.menu, {[s.active]: active === i.id})}
                                    onClick={() => handleActive(i.id)}
                                >
                                    {t(i?.text)}
                                </Text>
                            ))}
                            <LanguageSelect/>
                        </> :
                        <ActionIcon onClick={() => setOpened(true)} variant={'transparent'}>
                            <IconBurger/>
                        </ActionIcon>
                }
            </div>
            <Drawer
                position={'right'}
                size={'80%'}
                opened={opened}
                onClose={() => setOpened(false)}>
                <Flex direction={'column'} align={'flex-end'} rowGap={12}>
                    <LanguageSelect/>
                    {NavbarData.map((i) => (
                        <Text
                            key={i?.id}
                            component={'p'}
                            className={cx(s.menu, {[s.active]: active === i.id})}
                            onClick={() => handleActive(i.id)}
                        >
                            {t(i?.text)}
                        </Text>
                    ))}
                </Flex>
            </Drawer>
        </>
    );
};