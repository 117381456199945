import s from "./styles.module.scss"
import cx from "clsx";
import {Box, Title} from "@mantine/core";
import {Carousel} from "@mantine/carousel";
import {NewsCard} from "@/shared/ui";
import {ReactComponent as IconLeft} from "@/shared/assets/images/chevron-left.svg";
import {ReactComponent as IconRight} from "@/shared/assets/images/chevron-right.svg";
import {useNewsModalStore} from "@/features/news-modal/model";
import {useMediaQuery} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {useGetNewsQuery} from "@/entities/news-api/query.ts";
import {INews} from "@/entities/news-api/types.ts";


export const CompanyNews = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const setNewsModal = useNewsModalStore((s) => s.setNewsModal)

    const {data} = useGetNewsQuery(0)

    const indicatorsNumber = data?.data?.length === 4 ? 0 : (data?.data?.length / 4) * 39
    return (
        <div id={'section2'} className={cx(s.sectionWrapper, 'container')}>
            <Title
                order={2}
                className={'section-title'}
                dangerouslySetInnerHTML={{
                    __html: t('новостиПредприятия')
                }}
            />

            <Box h={data?.data?.length > 4 ? (matches ? 300 : 344) : 'auto'} className={s.cards}>
                <Carousel
                    withIndicators={data?.data?.length > 4}
                    withControls={data?.data?.length > 4}
                    height={ matches ? 'auto' : 200}
                    slideSize={matches ? '100%' :"25%"}
                    slideGap={"10px"}
                    align={'start'}
                    slidesToScroll={"auto"}
                    classNames={{
                        indicators: 'carousel-indicators',
                        indicator: 'carousel-indicator',
                        controls: 'carousel-controls',
                        control: 'carousel-control'
                    }}
                    styles={{
                        controls: {maxWidth: matches ? '320px' : `${450 + indicatorsNumber}px !important`}
                    }}
                    previousControlIcon={(
                        <>
                            <IconLeft/>
                            {!matches && t('назад')}
                        </>
                    )}
                    nextControlIcon={(
                        <>
                            {!matches && t('вперед')}
                            <IconRight/>
                        </>
                    )}
                >
                    {data?.data?.map((i:INews,index:number) => (
                        <Carousel.Slide key={index}>
                            <NewsCard
                                onClick={() => {
                                    localStorage.setItem('newsData', JSON.stringify(i));
                                    setNewsModal(true)
                                }}
                                text={i?.title || ''}
                                date={i?.published_at|| ''}
                            />
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Box>
        </div>
    );
};