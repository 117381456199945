export const NavbarData = [
    {
        id: 1,
        text: 'главная'
    },
    {
        id: 2,
        text: 'новости'
    },
    {
        id: 3,
        text: 'видыДеятельности'
    },
    {
        id: 4,
        text: 'кадроваяСтруктура'
    },
    {
        id: 5,
        text: 'финансовыеРезультаты'
    },
    {
        id: 6,
        text: 'обратнаяСвязь'
    }
]