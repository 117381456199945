import clientApi from "@/shared/api/base-api.ts";
import {apiKeys} from "@/shared/constants/api-keys.ts";

export const getPersonnelStructureApi = async (lang: string | null) => {
    const response = await clientApi.get(apiKeys.personnelStructure, {
        params: {
            per_page:'999',
            lang
        }
    })
    return response?.data?.data
}
export const getPersonnelStatisticsApi = async (lang: string | null) => {
    const response = await clientApi.get(apiKeys.personnelStatistics, {
        params: {
            lang
        }
    })
    return response?.data?.data
}