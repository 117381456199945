import clientApi from "@/shared/api/base-api.ts";
import {apiKeys} from "@/shared/constants/api-keys.ts";

export const getHeaderTitleApi = async (lang: string | null) => {
    const response = await clientApi.get(apiKeys.headline, {
        params: {
            lang
        }
    })
    return response?.data?.data
}