import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {FindLang} from "@/shared/libs/functions.ts";
import {getHeaderTitleApi} from "@/entities/header-title/index.ts";


export const useHeaderTitleQuery = () => {
    const lang = localStorage.getItem('language') || 'uz'
    return useQuery([apiKeys.headline, lang], () => getHeaderTitleApi(FindLang(lang)), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
