import s from "./styles.module.scss"
import {Flex, Modal, ScrollArea, Text} from "@mantine/core";
import {FilledButton} from "@/shared/ui";
import {useNewsModalStore} from "@/features/news-modal/model";
import {useMediaQuery} from "@mantine/hooks";
import dayjs from "dayjs";

export const NewsModal = () => {
    const matches = useMediaQuery('(max-width: 500px)');
    const [newsModal, setNewsModal] = useNewsModalStore((s) => [s.newsModal, s.setNewsModal])
    const newsData = JSON.parse(localStorage.getItem('newsData') || '{}');

    const onClearData = () => {
        setNewsModal(false)
        localStorage.removeItem('newsData')
    }
    return (
        <Modal
            opened={newsModal}
            onClose={onClearData}
            centered
            size={1356}
            radius={12}
            padding={24}
            title={<>
                <Text className={s.title} component={'p'}>
                    {newsData.title || '-'}
                </Text>
                <Text className={s.date} component={'p'}>
                    {dayjs(newsData.published_at).format('DD/MM/YYYY')}
                </Text>
            </>}
            classNames={{
                title: s.modalTitle
            }}
        >
            <ScrollArea h={matches ? 'calc(100vh - 470px)' : '420px'}>
                <Text className={s.texts}
                      dangerouslySetInnerHTML={{
                          __html: newsData.content
                      }}
                />
            </ScrollArea>
            <Flex mt={14} justify={'flex-end'}>
                <FilledButton w={matches ? '100%' : 200} onClick={onClearData}>
                    Ok
                </FilledButton>
            </Flex>
        </Modal>
    );
};