import React from "react"
import ReactDOM from "react-dom/client"

import App from "./app"
import { ErrorBoundary } from "./shared/ui/errors"
import "@/shared/libs/localisation/index.ts"


ReactDOM.createRoot(document.getElementById("root")!).render(
	// @ts-ignore
	<React.StrictMode>
		{/*@ts-ignore*/}
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>,
)
