import {Navbar} from "@/widgets/layout/navbar/ui";
import {Outlet} from "react-router-dom";
import {Footer} from "@/widgets/layout/footer/ui";
import {Else, If, Then} from "react-if";
import {TechnicalWork} from "@/features/technical-work/ui";
import {useMaintenanceQuery} from "@/features/technical-work/api/query.ts";
import {Center, Loader} from "@mantine/core";
import {useEffect} from "react";
import {useLanguageStore} from "@/entities/language-select/model";
import {useTranslation} from "react-i18next";

const supportedLanguages = ["uz", "ru"];
export const Layout = () => {
    const setLang = useLanguageStore((s) => s.setLang)
    const {i18n} =useTranslation()
    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");

        if (!savedLanguage) {
            const userLang = navigator.language;
            const selectedLanguage = supportedLanguages.includes(userLang.slice(0, 2))
                ? userLang.slice(0, 2)
                : "uz";

            setLang(selectedLanguage);
            i18n.changeLanguage(selectedLanguage);
            localStorage.setItem("language", selectedLanguage);
        } else {
            setLang(savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n,setLang]);


    const {data, isLoading} = useMaintenanceQuery()

    if (isLoading) {
        return (
            <Center w={'100%'} h={'100vh'}>
                <Loader size={'100px'} color={'#1f3f86'}/>
            </Center>
        )
    }
    return (
        <>
            <If condition={!data?.status}>
                <Then>
                    <Navbar/>
                    <Outlet/>
                    <Footer/>
                </Then>
                <Else>
                    <TechnicalWork data={data}/>
                </Else>
            </If>
        </>
    );
};