import s from "./style.module.scss"
import {Text} from "@mantine/core";
import {ReactComponent as Logo} from "@/shared/assets/images/logo.svg"
import {FC} from "react";
import {IMaintenance} from "@/features/technical-work/api/types.ts";

interface IMaintenanceData {
    data: IMaintenance
}

export const TechnicalWork: FC<IMaintenanceData> = ({data}) => {
    return (
        <div className={s.sectionWrapper}>
            <div className={s.bgEllipse}/>
            <div className={s.content}>
                <Logo className={s.logo}/>
                <Text className={s.title} component={'p'}>
                    {data?.title}
                </Text>
                <Text className={s.subTitle} component={'p'}>
                    {data?.subtitle}
                </Text>
            </div>
        </div>
    );
};