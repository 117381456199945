import s from "./styles.module.scss";
import {FilledButton, InputOutline} from "@/shared/ui";
import {Input, Textarea} from "@mantine/core";
import {Controller, useForm} from "react-hook-form";
import {IContactForm} from "@/features/contact-form/api/types.ts";
import {yupResolver} from "@hookform/resolvers/yup";
import {IContactScheme} from "@/features/contact-form/ui/scheme.ts";
import {useTranslation} from "react-i18next";
import {usePostContactFormQuery} from "@/features/contact-form/api/query.ts";

export const ContactForm = () => {
    const {t} = useTranslation()
    const {
        reset,
        control,
        handleSubmit,
        formState: {isDirty, isLoading, isValid},
    } = useForm<IContactForm>({
        mode: "onChange",
        defaultValues: {
            full_name: '',
            email: '',
            message: ''
        },
        resolver: yupResolver(IContactScheme)
    });
    const {mutate} = usePostContactFormQuery()
    const onSubmit = (data: IContactForm) => {
        mutate(data)
        reset();
    };

    return (
        <form className={s.form} id={"section6"} onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name={"full_name"}
                control={control}
                render={({field}) => (
                    <Input.Wrapper required className={s.inputWrapper} label={t('фамилияИмя')}>
                        <InputOutline placeholder={"ФИО"} {...field} />
                    </Input.Wrapper>
                )}
            />
            <Controller
                name={"email"}
                control={control}
                render={({field}) => (
                    <Input.Wrapper required className={s.inputWrapper} label={t('электроннаяПочта2')}>
                        <InputOutline type={"email"} placeholder={"olivia@untitledui.com"} {...field} />
                    </Input.Wrapper>
                )}
            />
            <Controller
                name={"message"}
                control={control}
                render={({field}) => (
                    <Input.Wrapper required className={s.inputWrapper} label={t('сообщение')}>
                        <Textarea placeholder={t('введитеОписание')} {...field} />
                    </Input.Wrapper>
                )}
            />

            <FilledButton
                fullWidth
                type={"submit"}
                disabled={!isDirty || !isValid}
                loading={isLoading}
            >
                {t('отправитьСообщение')}
            </FilledButton>
        </form>
    );
};
