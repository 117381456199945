import s from "./styles.module.scss"
import cx from "clsx";
import {Anchor, Flex, Text, Title} from "@mantine/core";
import {ReactComponent as IconClockRefresh} from "@/shared/assets/images/clock-refresh.svg";
import {ReactComponent as IconChevronUp} from "@/shared/assets/images/chevron-up.svg";
import {ReactComponent as IconChevronDown} from "@/shared/assets/images/chevron-down.svg";
import {ReactComponent as IconLeft} from "@/shared/assets/images/chevron-left.svg";
import {ReactComponent as IconRight} from "@/shared/assets/images/chevron-right.svg";
import {useMediaQuery} from "@mantine/hooks";
import {Carousel} from "@mantine/carousel";
import {useTranslation} from "react-i18next";
import {useGetFinancialResultsQuery} from "@/entities/financial-results/query.ts";
import {IFinancialResults, IResult} from "@/entities/financial-results/types.ts";

export const FinancialResults = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const {data} = useGetFinancialResultsQuery()
    const ProfitLoss = (num: number) => {
        return (
            <Text
                className={s.profitLossText}
                component={'p'}
                color={num >= 0 ? '#079455' : '#D92D20'}
            >
                <span>
                    {num.toLocaleString('en-US').replace(/,/g, ' ')}
                </span>
                {num >= 0 ? <IconChevronUp/> : <IconChevronDown/>}
            </Text>
        )
    }

    const indicatorWidth = Math.floor((data?.length / 3) * 50)

    return (
        <div id={'section5'} className={cx(s.sectionWrapper, 'container')}>
            <Title
                order={2}
                className={'section-title'}
                dangerouslySetInnerHTML={{
                    __html: t('финансовыеРезультатыПредприятия')
                }}
            />

            <div className={s.box}>
                <Flex align={'center'} gap={16}>
                    <IconClockRefresh/>
                    <Title
                        order={3}
                        className={'section-title md'}
                        dangerouslySetInnerHTML={{
                            __html: t('годовойОборот')
                        }}
                    />
                </Flex>

                <Carousel
                    mt={29}
                    height={340}
                    withIndicators
                    slideSize={matches ? '100%' : "33.33%"}
                    slideGap={"32px"}
                    align={'start'}
                    slidesToScroll={matches ? 1 : 'auto'}
                    classNames={{
                        indicators: 'carousel-indicators',
                        indicator: 'carousel-indicator',
                        controls: 'carousel-controls',
                        control: 'carousel-control'
                    }}
                    styles={{
                        controls: {maxWidth: matches ? '320px' : `${450 + indicatorWidth}px`}
                    }}
                    previousControlIcon={(
                        <>
                            <IconLeft/>
                            {!matches && t('назад')}
                        </>
                    )}
                    nextControlIcon={(
                        <>
                            {!matches && t('вперед')}
                            <IconRight/>
                        </>
                    )}
                >
                    {data?.slice()?.reverse()?.map((i: IFinancialResults, index: number) => {
                        const currentPeriodCount = i?.results.filter(item => item.current_period).length;
                        return (
                            <Carousel.Slide key={index}>
                                <div className={s.cardWrapper}>
                                    <div className={s.card}>
                                        <Text className={s.year} component={'p'}>
                                            {i?.year}
                                        </Text>
                                        <div className={s.cardTable}>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>{t('квартал')}</th>
                                                    <th>{t('оборот')}</th>
                                                    <th>{t('расход')}</th>
                                                    <th>
                                                        <span className={s.green}>{t('прибыль')}</span> <br/>
                                                        / <span className={s.red}>{t('убыток')}</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {i?.results?.map((r: IResult, index: number) => {
                                                    const profitLoss = r?.income - r?.expense
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{r?.income.toLocaleString()}</td>
                                                            <td>{r?.expense.toLocaleString()}</td>
                                                            <td>
                                                                {ProfitLoss(profitLoss)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td>{t('всего')}</td>
                                                    <td>
                                                        <b>
                                                            {i?.results?.reduce((acc, item) => acc + item.income, 0).toLocaleString()}
                                                        </b>
                                                    </td>
                                                    <td>
                                                        <b>
                                                            {i?.results?.reduce((acc, item) => acc + item.expense, 0).toLocaleString()}
                                                        </b>
                                                    </td>
                                                    <td>
                                                        <b>
                                                            {ProfitLoss(
                                                                i?.results?.reduce((acc, item) => acc + (item.income - item.expense), 0)
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {currentPeriodCount > 0 && (
                                                <Text className={cx(
                                                    s.currentPeriod,
                                                    {[s.h1]: currentPeriodCount === 1},
                                                    {[s.h2]: currentPeriodCount === 2},
                                                    {[s.h3]: currentPeriodCount === 3},
                                                    {[s.h4]: currentPeriodCount === 4},
                                                )}>
                                                    {t('текущийПериод')}
                                                </Text>
                                            )}
                                        </div>

                                        <Anchor download href={i?.url} className={s.link} component={'a'}>
                                            {t('таблицаБаланса')}
                                        </Anchor>
                                    </div>
                                </div>
                            </Carousel.Slide>
                        )
                    })}
                </Carousel>

                {/*{matches ? (*/}
                {/*   */}
                {/*) : (*/}
                {/*    <div className={s.cards}>*/}
                {/*        {data?.map((i: IFinancialResults, index: number) => {*/}
                {/*            const currentPeriodCount = i?.results.filter(item => item.current_period).length;*/}
                {/*            return (*/}
                {/*                <div key={index} className={s.cardWrapper}>*/}
                {/*                    <div className={s.card}>*/}
                {/*                        <Text className={s.year} component={'p'}>*/}
                {/*                            {i?.year}*/}
                {/*                        </Text>*/}
                {/*                        <div className={s.cardTable}>*/}
                {/*                            <table>*/}
                {/*                                <thead>*/}
                {/*                                <tr>*/}
                {/*                                    <th>{t('квартал')}</th>*/}
                {/*                                    <th>{t('оборот')}</th>*/}
                {/*                                    <th>{t('расход')}</th>*/}
                {/*                                    <th>*/}
                {/*                                        <span className={s.green}>{t('прибыль')}</span> <br/>*/}
                {/*                                        / <span className={s.red}>{t('убыток')}</span>*/}
                {/*                                    </th>*/}
                {/*                                </tr>*/}
                {/*                                </thead>*/}
                {/*                                <tbody>*/}
                {/*                                {i?.results?.map((r: IResult, index: number) => {*/}
                {/*                                    const profitLoss = r?.income - r?.expense*/}
                {/*                                    return (*/}
                {/*                                        <tr key={index}>*/}
                {/*                                            <td>{index + 1}</td>*/}
                {/*                                            <td>{r?.income.toLocaleString()}</td>*/}
                {/*                                            <td>{r?.expense.toLocaleString()}</td>*/}
                {/*                                            <td>*/}
                {/*                                                {ProfitLoss(profitLoss)}*/}
                {/*                                            </td>*/}
                {/*                                        </tr>*/}
                {/*                                    )*/}
                {/*                                })}*/}
                {/*                                <tr>*/}
                {/*                                    <td>{t('всего')}</td>*/}
                {/*                                    <td>*/}
                {/*                                        <b>*/}
                {/*                                            {i?.results?.reduce((acc, item) => acc + item.income, 0).toLocaleString()}*/}
                {/*                                        </b>*/}
                {/*                                    </td>*/}
                {/*                                    <td>*/}
                {/*                                        <b>*/}
                {/*                                            {i?.results?.reduce((acc, item) => acc + item.expense, 0).toLocaleString()}*/}
                {/*                                        </b>*/}
                {/*                                    </td>*/}
                {/*                                    <td>*/}
                {/*                                        <b>*/}
                {/*                                            {ProfitLoss(*/}
                {/*                                                i?.results?.reduce((acc, item) => acc + (item.income - item.expense), 0)*/}
                {/*                                            )}*/}
                {/*                                        </b>*/}
                {/*                                    </td>*/}
                {/*                                </tr>*/}
                {/*                                </tbody>*/}
                {/*                            </table>*/}
                {/*                            {currentPeriodCount > 0 && (*/}
                {/*                                <Text className={cx(*/}
                {/*                                    s.currentPeriod,*/}
                {/*                                    {[s.h1]: currentPeriodCount === 1},*/}
                {/*                                    {[s.h2]: currentPeriodCount === 2},*/}
                {/*                                    {[s.h3]: currentPeriodCount === 3},*/}
                {/*                                    {[s.h4]: currentPeriodCount === 4},*/}
                {/*                                )}>*/}
                {/*                                    {t('текущийПериод')}*/}
                {/*                                </Text>*/}
                {/*                            )}*/}
                {/*                        </div>*/}

                {/*                        <Anchor download href={i?.url} className={s.link} component={'a'}>*/}
                {/*                            {t('таблицаБаланса')}*/}
                {/*                        </Anchor>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </div>
    );
};