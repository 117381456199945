export enum apiKeys {
	news = "news",
	footer = "footers",
	coreActivities = "core-activities",
	personnelStatistics = "statistics",
	personnelStructure = "executive-staffs",
	financialResults = "financial-results",
	headline = "headline",
	maintenance = "maintenance",
	contactForm = "contact-form",
}
