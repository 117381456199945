import s from "./styles.module.scss"
import cx from "clsx";
import {Flex, Text, Title} from "@mantine/core";
import {ReactComponent as IconBrowser} from "@/shared/assets/images/terminal-browser.svg";
import {ReactComponent as IconUserCheck} from "@/shared/assets/images/user-check.svg";
import {ReactComponent as IconLeft} from "@/shared/assets/images/chevron-left.svg";
import {ReactComponent as IconRight} from "@/shared/assets/images/chevron-right.svg";
import {Carousel} from "@mantine/carousel";
import {useMediaQuery} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {useGetCoreActivitiesQuery} from "@/entities/core-activities/query.ts";
import {IMainActivities} from "@/entities/core-activities/types.ts";


export const MainActivities = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const {data} = useGetCoreActivitiesQuery(1)
    const {data:DataServiceProvision} = useGetCoreActivitiesQuery(2)
    return (
        <div id={'section3'} className={cx(s.sectionWrapper, 'container')}>
            <Title
                order={2}
                className={'section-title'}
                dangerouslySetInnerHTML={{
                    __html: t('основныеВидыДеятельности')
                }}
            />
            <div className={cx(s.box,{[s.p]:data?.length > 3})}>
                <Flex align={'center'} gap={16}>
                    <IconBrowser/>
                    <Title
                        order={3}
                        className={'section-title md'}
                        dangerouslySetInnerHTML={{
                            __html: t('ITРазработка')
                        }}
                    />
                </Flex>
                <Carousel
                    mt={29}
                    withIndicators={data?.length > 3}
                    withControls={data?.length > 3}
                    height={'auto'}
                    slideSize={matches ? '100%' : "33.33%"}
                    slideGap={"32px"}
                    align={'start'}
                    slidesToScroll={matches ? 1 : 'auto'}
                    classNames={{
                        indicators: 'carousel-indicators',
                        indicator: 'carousel-indicator',
                        controls: 'carousel-controls',
                        control: 'carousel-control'
                    }}
                    styles={{
                        controls: {maxWidth: matches ? '320px' : '450px'}
                    }}
                    previousControlIcon={(
                        <>
                            <IconLeft/>
                            {!matches && t('назад')}
                        </>
                    )}
                    nextControlIcon={(
                        <>
                            {!matches && t('вперед')}
                            <IconRight/>
                        </>
                    )}
                >
                    {data?.map((i: IMainActivities,index:number) => (
                        <Carousel.Slide key={index}>
                            <div className={s.card}>
                                <Text component={'p'} className={s.cardTitle}>
                                    {i?.title}
                                </Text>
                                <Text className={s.cardDescription}
                                      dangerouslySetInnerHTML={{
                                          __html: i.content
                                      }}
                                />
                            </div>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </div>

            <div className={cx(s.box,{[s.p]:DataServiceProvision?.length > 2})}>
                <Flex align={'center'} gap={16}>
                    <IconUserCheck/>
                    <Title
                        order={3}
                        className={'section-title md'}
                        dangerouslySetInnerHTML={{
                            __html: t('сервисноеОбслуживаниеНаселения')
                        }}
                    />
                </Flex>

                <Carousel
                    mt={29}
                    withIndicators={DataServiceProvision?.length > 2}
                    withControls={DataServiceProvision?.length > 2}
                    height={'auto'}
                    slideSize={matches ? '100%' : "50%"}
                    slideGap={"32px"}
                    align={'start'}
                    slidesToScroll={matches ? 1 : 'auto'}
                    classNames={{
                        indicators: 'carousel-indicators',
                        indicator: 'carousel-indicator',
                        controls: 'carousel-controls',
                        control: 'carousel-control'
                    }}
                    styles={{
                        controls: {maxWidth: matches ? '320px' : '450px'}
                    }}
                    previousControlIcon={(
                        <>
                            <IconLeft/>
                            {!matches && t('назад')}
                        </>
                    )}
                    nextControlIcon={(
                        <>
                            {!matches && t('вперед')}
                            <IconRight/>
                        </>
                    )}
                >
                    {DataServiceProvision?.map((i: IMainActivities,index:number) => (
                        <Carousel.Slide key={index}>
                            <div className={s.card}>
                                <Text component={'p'} className={s.cardTitle}>
                                    {i?.title}
                                </Text>
                                <Text className={s.cardDescription}
                                      dangerouslySetInnerHTML={{
                                          __html: i.content
                                      }}
                                />
                            </div>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};