import { Input } from "@mantine/core"
import cx from "clsx"
import { forwardRef } from "react"

import s from "./styles.module.scss"
import { IInputStyled } from "./types.ts"

export const InputOutline = forwardRef<HTMLInputElement, IInputStyled>(
  ({ error, height = 36, className, ...props }: IInputStyled, ref) => (
    <Input
      ref={ref}
      styles={{
        input: { height: height },
      }}
      className={cx(s.input, className, { [s.error]: error })}
      {...props}
    />
  ),
)
