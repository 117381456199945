export const FindLang = (key: string) => {
    if (key === 'uz') {
        return 'lt'
    } else if (key === 'oz') {
        return 'cr'
    } else if (key === 'ru') {
        return 'ru'
    } else {
        return 'ru'
    }
}