import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {FindLang} from "@/shared/libs/functions.ts";
import {getMaintenanceApi} from "@/features/technical-work/api/index.ts";


export const useMaintenanceQuery = () => {
    const lang = localStorage.getItem('language') || 'uz'
    return useQuery([apiKeys.maintenance, lang], () => getMaintenanceApi(FindLang(lang)), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
