import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import oz from "./oz.json"
import ru from "./ru.json"
import uz from "./uz.json"

i18n
	.use(initReactI18next)
	.init({
		resources: {
			ru: {
				translation: ru,
			},
			uz: {
				translation: uz,
			},
			oz: {
				translation: oz,
			},
		},
		lng: "uz",
		fallbackLng: "uz",

		interpolation: {
			escapeValue: false,
		},
	})
