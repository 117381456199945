import s from "./styles.module.scss"
import cx from "clsx";
import {ReactComponent as Logo} from "@/shared/assets/images/logo.svg";
import {Box, Flex} from "@mantine/core";
import {NewsCard} from "@/shared/ui";
import {useNewsModalStore} from "@/features/news-modal/model";
import {useMediaQuery} from "@mantine/hooks";
import {useGetNewsQuery} from "@/entities/news-api/query.ts";
import {INews} from "@/entities/news-api/types.ts";
import {useHeaderTitleQuery} from "@/entities/header-title/query.ts";
import {useTranslation} from "react-i18next";

export const MainSection = () => {
    useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const setNewsModal = useNewsModalStore((s) => s.setNewsModal)
    const {data} = useGetNewsQuery(1)
    const {data: head} = useHeaderTitleQuery()


    return (
        <div id={'section1'} className={cx(s.sectionWrapper, 'container')}>
            <div className={s.content}>
                <Logo/>
                <Box
                    className={s.texts}
                    dangerouslySetInnerHTML={{
                        __html: head?.title
                    }}
                />
            </div>
            {!matches && <Flex mt={24} align={'center'} gap={32}>
                {data?.data?.slice(0, 4).map((i: INews, index: number) => (
                    <NewsCard
                        key={index}
                        onClick={() => {
                            localStorage.setItem('newsData', JSON.stringify(i));
                            setNewsModal(true)
                        }}
                        text={i?.title || ''}
                        date={i?.published_at || ''}
                    />
                ))}
            </Flex>}
        </div>
    );
};