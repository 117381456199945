import clientApi from "@/shared/api/base-api.ts";
import {apiKeys} from "@/shared/constants/api-keys.ts";

export const getNewsApi = async (lang: string | null, main: number) => {
    const response = await clientApi.get(apiKeys.news, {
        params: {
            top: main,
            per_page: 999,
            lang: lang
        }
    })
    return response?.data
}