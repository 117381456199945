import s from "./styles.module.scss"
import cx from "clsx";
import {Box, Flex, Image, Text, Title} from "@mantine/core";
import {ReactComponent as IconBuilding} from "@/shared/assets/images/building.svg";
import {ReactComponent as IconUserSquare} from "@/shared/assets/images/user-square.svg";
import {ReactComponent as IconLeft} from "@/shared/assets/images/chevron-left.svg";
import {ReactComponent as IconRight} from "@/shared/assets/images/chevron-right.svg";
import CountUp from "react-countup";
import {useMediaQuery} from "@mantine/hooks";
import {Carousel} from "@mantine/carousel";
import {useTranslation} from "react-i18next";
import {useGetPersonnelStructureQuery, usePersonnelStatisticsQuery} from "@/entities/personnel-structure/query.ts";
import {IExecutiveStaffs, IPersonnelStatistics} from "@/entities/personnel-structure/types.ts";
import {EnvKeys} from "@/shared/constants/env.ts";


export const PersonnelStructure = () => {
    const {t} = useTranslation()
    const matches = useMediaQuery('(max-width: 500px)');
    const {data} = useGetPersonnelStructureQuery()
    const {data: dataStatistics} = usePersonnelStatisticsQuery()
    return (
        <div id={'section4'} className={cx(s.sectionWrapper, 'container')}>
            <Title
                order={2}
                className={'section-title'}
                dangerouslySetInnerHTML={{
                    __html: t('кадроваяСтруктура2')
                }}
            />

            <div className={cx(s.box,{[s.p]:data?.length > 3})}>
                <Flex align={'center'} gap={16}>
                    <IconBuilding className={'section-head-icon'}/>
                    <Title
                        order={3}
                        className={'section-title md'}
                        dangerouslySetInnerHTML={{
                            __html: t('руководящийСостав')
                        }}
                    />
                </Flex>

                <Carousel
                    mt={29}
                    withIndicators={data?.length > 3}
                    withControls={data?.length > 3}
                    height={'auto'}
                    slideSize={matches ? '100%' : "33.33%"}
                    slideGap={"32px"}
                    align={"start"}
                    slidesToScroll={matches ? 1 : 'auto'}
                    classNames={{
                        indicators: 'carousel-indicators',
                        indicator: 'carousel-indicator',
                        controls: 'carousel-controls',
                        control: 'carousel-control'
                    }}
                    styles={{
                        controls: {maxWidth: matches ? '320px' : '450px'}
                    }}
                    previousControlIcon={(
                        <>
                            <IconLeft/>
                            {!matches && t('назад')}
                        </>
                    )}
                    nextControlIcon={(
                        <>
                            {!matches && t('вперед')}
                            <IconRight/>
                        </>
                    )}
                >
                    {data?.map((i:IExecutiveStaffs,index:number) => (
                        <Carousel.Slide key={index}>
                            <Flex className={s.card} align={'center'} gap={16}>
                                <Image
                                    src={`${EnvKeys.IMAGE_HOST}${i?.image}`}
                                    alt={'image-one'}
                                    withPlaceholder
                                    radius={100}
                                    width={100}
                                    height={100}
                                />
                                <Box>
                                    <Text component={'p'} className={s.name}>
                                        {i?.full_name}
                                    </Text>
                                    <Text component={'p'} className={s.description}>
                                        {i?.position}
                                    </Text>
                                </Box>
                            </Flex>
                        </Carousel.Slide>
                    ) )}
                </Carousel>
            </div>
            <div className={s.box}>
                <Flex align={'center'} gap={16}>
                    <IconUserSquare className={'section-head-icon'}/>
                    <Title
                        order={3}
                        className={'section-title md'}
                        dangerouslySetInnerHTML={{
                            __html: t('статистикаКадрам')
                        }}
                    />
                </Flex>
                <Flex direction={matches ? 'column' : 'row'} mt={29} align={'stretch'} gap={matches ? 16 : 32}
                      w={'100%'}>

                    {dataStatistics?.map((i:IPersonnelStatistics,index:number) => (
                        <div key={index} className={s.card}>
                            <Text component={'p'} className={s.label}>
                                {i?.title}
                            </Text>
                            <Text component={'p'} className={s.statisticText}>
                                <CountUp  scrollSpyOnce={true} enableScrollSpy={true} end={Number(i?.value)} duration={3}/>
                                {t('человек')}
                            </Text>
                        </div>
                    ))}
                </Flex>
            </div>
        </div>
    );
};