import clientApi from "@/shared/api/base-api.ts";
import {apiKeys} from "@/shared/constants/api-keys.ts";

export const getFooterApi = async (lang: string | null) => {
    const response = await clientApi.get(apiKeys.footer, {
        params: {
            lang: lang
        }
    })
    return response?.data
}