import {FC} from "react";
import {INewsCard} from "./types.ts";
import s from "./styles.module.scss"
import {Text} from "@mantine/core";
import dayjs from "dayjs";

export const NewsCard: FC<INewsCard> = ({onClick,text,date}) => {
    return (
        <div
            className={s.card}
            onClick={onClick}
        >
            <Text content={'p'} className={s.text}>
                {text}
            </Text>
            <Text component={'p'} className={s.date}>
                {dayjs(date).format('DD/MM/YYYY')}
            </Text>
        </div>
    );
};