import {Popover, Text} from "@mantine/core";
import cx from "clsx";
import s from "@/widgets/layout/navbar/ui/styles.module.scss";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLanguageStore} from "@/entities/language-select/model";

export const LanguageSelect = () => {
    const {t, i18n} = useTranslation();
    const [opened, setOpened] = useState(false);
    const [lang,setLang] = useLanguageStore((s) => [s.lang,s.setLang])



    const handleChangeLanguage = (lang: string) => {
        setLang(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
        setOpened(false);
    };
    return (
        <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            onChange={setOpened}
            position={'bottom-end'}>
            <Popover.Target>
                <Text
                    onClick={() => setOpened(!opened)}
                    className={cx(s.menu, s.lang)}
                >
                    {t('язык')}: <span>{(lang === 'oz' && 'Уз') || (lang === 'ru' && 'Ру') || lang}</span>
                </Text>
            </Popover.Target>
            <Popover.Dropdown>
                <Text
                    className={cx(s.langValue, {[s.active]: lang === 'uz'})}
                    onClick={() => handleChangeLanguage('uz')}
                >
                    Uz
                </Text>
                <Text
                    className={cx(s.langValue, {[s.active]: lang === 'oz'})}
                    onClick={() => handleChangeLanguage('oz')}
                >
                    Уз
                </Text>
                <Text
                    className={cx(s.langValue, {[s.active]: lang === 'ru'})}
                    onClick={() => handleChangeLanguage('ru')}
                >
                    Ру
                </Text>
            </Popover.Dropdown>
        </Popover>
    );
};