import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {ILanguageStore, IInitialState} from './types';

const initialState: IInitialState = {
    lang: localStorage.getItem("language") || "uz",
};

export const useLanguageStore = create<ILanguageStore>()(
    devtools((set) => ({
        ...initialState,
        setLang: (e) => {
            set({lang: e});
        }
    })),
);
