import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {getFinancialResultsApi} from "@/entities/financial-results/index.ts";

export const useGetFinancialResultsQuery = () => {
    return useQuery([apiKeys.financialResults,1], () => getFinancialResultsApi(), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
