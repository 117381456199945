import s from "./styles.module.scss"
import cx from "clsx";
import {Text} from "@mantine/core";
import {ContactForm} from "@/features";
import {useGetFooterQuery} from "@/entities/footer-api/query.ts";
import {IFooterData} from "@/entities/footer-api/types.ts";
import {useTranslation} from "react-i18next";

export const Footer = () => {
    useTranslation()
    const {data} = useGetFooterQuery()
    const sortedData = data?.data?.sort((a: any, b: any) => a?.id - b?.id);
    return (
        <div className={s.footerWrapper}>
            <div className={cx(s.footer, 'container')}>
                <div className={cx(s.texts, {[s.wrap]: sortedData?.length > 4})}>
                    {sortedData?.map((i: IFooterData, index: number) => (
                        <Text key={index} className={s.text} component={'p'}
                              dangerouslySetInnerHTML={{
                                  __html: i?.context || '-'
                              }}
                        />
                    ))}
                </div>
                {/*<div className={s.contact}>*/}
                {/*    <Box>*/}
                {/*        <Text className={s.text} component={'p'}>*/}
                {/*            {t('адрес')}*/}
                {/*        </Text>*/}
                {/*        <Text className={s.contactText} component={'p'}>*/}
                {/*            г. Ташкент, Яккасарайский р-н,<br/>*/}
                {/*            ул. Ю. Ражабий, д. 1*/}
                {/*        </Text>*/}
                {/*    </Box>*/}
                {/*    <Box m={'16px 0'}>*/}
                {/*        <Text className={s.text} component={'p'}>*/}
                {/*            {t('электроннаяПочта')}*/}
                {/*        </Text>*/}
                {/*        <Anchor className={s.contactText} component={'p'}>*/}
                {/*            gup_innovatsiya@exat.uz*/}
                {/*        </Anchor>*/}
                {/*    </Box>*/}
                {/*    <Box>*/}
                {/*        <Text className={s.text} component={'p'}>*/}
                {/*            {t('часыРаботы')}*/}
                {/*        </Text>*/}
                {/*        <Text className={s.contactText} component={'p'}>*/}
                {/*            9:00 - 18:00 <br/>*/}
                {/*            понедельник-пятница*/}
                {/*        </Text>*/}
                {/*    </Box>*/}
                {/*</div>*/}
                <ContactForm/>
            </div>
        </div>
    );
};