import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {getFooterApi} from "@/entities/footer-api";
import {FindLang} from "@/shared/libs/functions.ts";

export const useGetFooterQuery = () => {
    const lang = localStorage.getItem('language') || 'uz'
    return useQuery([apiKeys.news,lang], () => getFooterApi(FindLang(lang)), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}