import {CompanyNews, FinancialResults, MainActivities, MainSection, NewsModal, PersonnelStructure} from "@/features";

const HomePage = () => {
    return (
        <>
            <MainSection/>
            <CompanyNews/>
            <MainActivities/>
            <PersonnelStructure/>
            <FinancialResults/>
            <NewsModal/>
        </>
    )
}

export default HomePage
