import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {FindLang} from "@/shared/libs/functions.ts";
import {getPersonnelStatisticsApi, getPersonnelStructureApi} from "@/entities/personnel-structure";

export const useGetPersonnelStructureQuery = () => {
    const lang = localStorage.getItem('language') || 'uz'

    return useQuery([apiKeys.personnelStructure, lang], () => getPersonnelStructureApi(FindLang(lang)), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
export const usePersonnelStatisticsQuery = () => {
    const lang = localStorage.getItem('language') || 'uz'

    return useQuery([apiKeys.personnelStatistics, lang], () => getPersonnelStatisticsApi(FindLang(lang)), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
