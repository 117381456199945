import {useQuery} from "@tanstack/react-query";
import {apiKeys} from "@/shared/constants/api-keys.ts";
import {toast} from "react-toastify";
import {getCoreActivitiesApi} from "@/entities/core-activities";
import {FindLang} from "@/shared/libs/functions.ts";

export const useGetCoreActivitiesQuery = (type: number) => {
    const lang = localStorage.getItem('language') || 'uz'

    return useQuery([apiKeys.coreActivities, type, lang], () => getCoreActivitiesApi(FindLang(lang), type), {
        select: (data) => data,
        keepPreviousData: true,
        retry: 0,
        onError: (error: any) => {
            toast.error(error.message)
        },
    })
}
