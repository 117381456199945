import {useMutation} from "@tanstack/react-query";
import {IContactForm} from "@/features/contact-form/api/types.ts";
import {postContactForm} from "@/features/contact-form/api/index.ts";
import {toast} from "react-toastify";

export const usePostContactFormQuery = () => {
    return useMutation((data: IContactForm) => postContactForm(data), {
        retry: 0,
        onSuccess: () => {
            toast.success('Сообщение успешно отправлено')
        },
        onError: (res: any) => {
            toast.error(res.message)
        },
    })
}